import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { produce } from 'immer';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CustomerTags } from 'src/modules/customer/model/enums/customer-tags';
import { CustomerState } from 'src/modules/customer/state/customer.state';
import {
  CatalogueTags,
  ICatalogue,
  ICatalogueFilter,
  ICatalogueMetadata,
  ICatalogueResult,
  SortableProperty,
} from '../models/catalogue.model';
import { ICatalogueCategory } from '../models/categories.model';
import { CatalogueService } from '../services/catalogue.service';
import {
  GetAllProducts,
  GetCategories,
  GetMicrosoftCatalogueInCatalogue,
  GetPopularMicrosoftAddons,
  GetPopularMicrosoftLicenses,
  GetProduct,
  GetProductBySku,
} from './catalogue.actions';

export class CatalogueStateModel {
  public categories: ICatalogueCategory[];
  // public products: ICatalogue[]; //used for the main products list on the catalogues-products page (filtering too)
  public selectedProduct: ICatalogue;
  public allProducts: ICatalogue[];
  public productsMetadata: ICatalogueMetadata;
  public areProductsLoading: boolean;

  public microsoftProducts: IProduct[];
  public popularMicrosoftLicenses: IProduct[];
  public popularMicrosoftAddons: IProduct[];

  public arePopularLicensesLoading: boolean;
  public arePopularAddonsLoading: boolean;
  public areMicrosoftSubscriptionsLoading: boolean;
}

const defaults = {
  categories: [],
  // products: [],
  selectedProduct: null,
  allProducts: [],
  productsCart: [],
  productsMetadata: null,
  areProductsLoading: true,

  microsoftProducts: [], //used for filtering on provision nce license
  popularMicrosoftLicenses: [], //used for getting and displayed the top recomended 25 licenses + filtering on provision nce license
  popularMicrosoftAddons: [], //used for getting and displayed the top recomended 25 addons on provision nce license

  arePopularLicensesLoading: true,
  arePopularAddonsLoading: true,
  areMicrosoftSubscriptionsLoading: false,
};

@State<CatalogueStateModel>({
  name: 'catalogue',
  defaults,
})
@Injectable()
export class CatalogueState {
  @Selector()
  public static getAllCategories(state: CatalogueStateModel) {
    return state.categories;
  }

  // @Selector() public static getProducts(state: CatalogueStateModel) { return state.products; }
  @Selector()
  public static getAllProducts(state: CatalogueStateModel) {
    return state.allProducts;
  }

  @Selector()
  public static getProductsMetadata(state: CatalogueStateModel) {
    return state.productsMetadata;
  }

  @Selector()
  public static areProductsLoading(state: CatalogueStateModel) {
    return state.areProductsLoading;
  }

  @Selector()
  public static getSelectedCatalogueProduct(state: CatalogueStateModel) {
    return state.selectedProduct;
  }

  @Selector()
  public static getPopularMicrosoftLicenses(state: CatalogueStateModel) {
    return state.popularMicrosoftLicenses;
  }

  @Selector()
  public static getPopularMicrosoftAddons(state: CatalogueStateModel) {
    return state.popularMicrosoftAddons;
  }

  @Selector()
  public static getMicrosoftProducts(state: CatalogueStateModel) {
    return state.microsoftProducts;
  }

  @Selector()
  public static arePopularLicensesLoading(state: CatalogueStateModel) {
    return state.arePopularLicensesLoading;
  }

  @Selector()
  public static arePopularAddonsLoading(state: CatalogueStateModel) {
    return state.arePopularAddonsLoading;
  }

  @Selector()
  public static areMicrosoftSubscriptionsLoading(state: CatalogueStateModel) {
    return state.areMicrosoftSubscriptionsLoading;
  }

  constructor (private catalogueService: CatalogueService, private toastr: ToastrService, private store: Store) {
  }

  @Action(GetCategories, { cancelUncompleted: true })
  public getCategories(ctx: StateContext<CatalogueStateModel>) {
    return this.catalogueService.getCategories().pipe(
      tap((categoriesArr: ICatalogueCategory[]) => {
        const state = produce(ctx.getState(), (draft) => {
          draft.categories = categoriesArr;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  @Action(GetProduct, { cancelUncompleted: true })
  public GetProduct(ctx: StateContext<CatalogueStateModel>, { productId, customerId }: GetProduct) {
    return this.catalogueService.getCatalogueProduct(productId, customerId).pipe(
      tap((product) => {
        const state = produce(ctx.getState(), (draft) => {
          draft.selectedProduct = product;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  @Action(GetProductBySku, { cancelUncompleted: true })
  public GetProductBySku(ctx: StateContext<CatalogueStateModel>, { filterSettings, billingCycle, commitment }: GetProductBySku) {
    return this.catalogueService.getCatalogueProducts(filterSettings).pipe(
      tap((result: ICatalogueResult) => {
        const state = produce(ctx.getState(), (draft) => {
          const product = result.results.find((x) => x.product.billingTerm == billingCycle && x.product.recursionTerm == commitment);
          draft.selectedProduct = product;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  @Action(GetAllProducts)
  public async GetAllProducts(ctx: StateContext<CatalogueStateModel>, { filterSettings }: GetAllProducts) {
    const products$ = this.catalogueService.getCatalogueProducts(filterSettings);
    const catalogueData = await firstValueFrom(products$);

    let catalogue: ICatalogue[] = catalogueData.results;

    filterSettings.pageIndex += 1;

    while (catalogue.length < catalogueData.metadata.totalRecords) {
      const products$ = this.catalogueService.getCatalogueProducts(filterSettings);
      const res = await firstValueFrom(products$);

      catalogue = [...catalogue, ...res.results];

      filterSettings.pageIndex += 1;
    }

    ctx.setState(
      produce(ctx.getState(), (draft) => {
        draft.allProducts = catalogue;
      }),
    );
  }

  @Action(GetPopularMicrosoftLicenses, { cancelUncompleted: true })
  public getPopularMicrosoftLicenses(ctx: StateContext<CatalogueStateModel>, { filterSettings }: GetPopularMicrosoftLicenses) {
    const filter: ICatalogueFilter = {
      ...filterSettings,
      filter: { sortBy: SortableProperty.Rank },
      pageSize: 100,
    };

    return this.catalogueService.getCatalogueProducts(filter).pipe(
      tap((result: ICatalogueResult) => {
        const customer = this.store.selectSnapshot(CustomerState.getCustomerObject);

        const catalogues = result.results.filter((catalogue) => {
          if (catalogue.product.rank > 0 && catalogue.product.rank !== null && catalogue.product.rank !== undefined) {
            return catalogue;
          }
        });

        const groupedProducts: IProduct[] = [];
        const grouped = groupBy(catalogues, (catalog) => catalog.product.sku);

        //@logic to group duplicate products by sku
        grouped.forEach((group) => {
          if (group) {
            if (!customer.tags.includes(CustomerTags.AllowHighRiskProducts)) {
              //filter out
              const filteredProducts = group.filter((product) => !product.product.tags.includes(CatalogueTags.HighRisk));
              group = filteredProducts;
            }

            const product: IProduct = {
              name: group[0]?.product.name,
              sku: group[0]?.product.sku,
              catalogue: group,
            };
            groupedProducts.push(product);
          }
        });

        ctx.setState(
          produce(ctx.getState(), (draft) => {
            draft.popularMicrosoftLicenses = groupedProducts;
            draft.arePopularLicensesLoading = false;
          }),
        );
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        this.toastr.error('Unable to get NCE Licenses', 'Error');
        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  @Action(GetPopularMicrosoftAddons, { cancelUncompleted: true })
  public getPopularMicrosoftAddons(ctx: StateContext<CatalogueStateModel>, { filterSettings }: GetPopularMicrosoftAddons) {
    const topAddonSkus = [
      'CFQ7TTC0MM8R:0002',
      'CFQ7TTC0LH0S:0001',
      'CFQ7TTC0LH0R:0001',
      'CFQ7TTC0LH0T:0001',
      'CFQ7TTC0JN4R:0002',
      'CFQ7TTC0LHXH:0001',
      'CFQ7TTC0LH04:0001',
      'CFQ7TTC0LH0D:0001',
      'CFQ7TTC0LGV0:0003',
      'CFQ7TTC0LGV0:0002',
      'CFQ7TTC0J1GB:0003',
      'CFQ7TTC0LH03:0003',
      'CFQ7TTC0LHSL:0001',
      'CFQ7TTC0LGZM:0001',
      'CFQ7TTC0LHQ5:0001',
      'CFQ7TTC0LH0J:0001',
      'CFQ7TTC0LHT4:0001',
      'CFQ7TTC0LHQ3:0001',
    ];

    const filter: ICatalogueFilter = {
      ...filterSettings,
      pageSize: 48,
      filter: { SKUs: topAddonSkus },
    };

    const customer = this.store.selectSnapshot(CustomerState.getCustomerObject);

    return this.catalogueService.getCatalogueProducts(filter).pipe(
      tap((result: ICatalogueResult) => {
        const products: IProduct[] = [];

        //@grouping the products by sku
        const grouped = groupBy(result.results, (result) => result.product.sku);

        topAddonSkus.forEach((sku) => {
          let skuProducts = grouped.get(sku);

          if (skuProducts) {
            //@if the customer is not allowed to have high risk products, filter them out
            if (!customer.tags.includes(CustomerTags.AllowHighRiskProducts)) {
              const filteredProducts = skuProducts.filter((license) => !license.product.tags.includes(CatalogueTags.HighRisk));
              skuProducts = filteredProducts;
            }

            const product: IProduct = {
              name: skuProducts[0]?.product.name,
              sku: skuProducts[0]?.product.sku,
              catalogue: skuProducts,
            };
            products.push(product);
          }
        });

        ctx.setState(
          produce(ctx.getState(), (draft) => {
            draft.popularMicrosoftAddons = products;
            draft.arePopularAddonsLoading = false;
          }),
        );
      }),
    );
  }

  @Action(GetMicrosoftCatalogueInCatalogue, { cancelUncompleted: true })
  public GetMicrosoftCatalogue(ctx: StateContext<CatalogueStateModel>, { filterSettings }: GetMicrosoftCatalogueInCatalogue) {
    ctx.setState({ ...ctx.getState(), areMicrosoftSubscriptionsLoading: true });

    return this.catalogueService.getCatalogueProducts(filterSettings).pipe(
      tap((result: ICatalogueResult) => {
        const customer = this.store.selectSnapshot(CustomerState.getCustomerObject);

        const products: IProduct[] = [];
        const grouped = groupBy(result.results, (result) => result.product.sku);

        grouped.forEach((group) => {
          if (group) {
            if (!customer.tags.includes(CustomerTags.AllowHighRiskProducts)) {
              const filteredProducts = group.filter((license) => !license.product.tags.includes(CatalogueTags.HighRisk));
              group = filteredProducts;
            }

            if (group.length == 0) {
              return;
            }

            const product: IProduct = {
              name: group[0]?.product.name,
              sku: group[0]?.product.sku,
              catalogue: group,
            };

            if (!products.some((e) => e.sku === product.sku)) {
              products.push(product);
            }
          }
        });

        const state = produce(ctx.getState(), (draft) => {
          draft.microsoftProducts = products;
          draft.areMicrosoftSubscriptionsLoading = false;
        });

        ctx.setState(state);
      }),
      catchError((error: HttpErrorResponse): Observable<any> => {
        this.toastr.error('Unable to get NCE Licenses', 'Error');
        ctx.setState({ ...ctx.getState(), areMicrosoftSubscriptionsLoading: false });

        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }
}

export interface IProduct {
  name: string;
  sku: string;
  catalogue: ICatalogue[];
}

//group by key (used for grouping products by sku)
function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
