import { ICustomer } from 'src/modules/customer/model/customer.model';
import { ICatalogueCart, ICatalogueFilter, ICatalogueProduct } from '../models/catalogue.model';

export class GetCategories {
  static readonly type = '[Catalogue] GetCategories';
}

export class GetProduct {
  static readonly type = '[Catalogue] GetProduct';
  constructor(public productId: ICatalogueProduct['id'], public customerId: ICustomer['id']) {}
}

// export class GetProducts {
//   static readonly type = '[Catalogue] GetProducts';
//   constructor(public filterSettings: ICatalogueFilter, public isScrolling: boolean) {}
// }

export class GetProductBySku {
  static readonly type = '[Catalogue] GetProductBySku';
  constructor(public filterSettings: ICatalogueFilter, public billingCycle: any, public commitment: any) {}
}

export class GetAllProducts {
  static readonly type = '[Catalogue] GetAllProducts';
  constructor(public filterSettings: ICatalogueFilter) {}
}

export class GetMicrosoftCatalogueInCatalogue {
  static readonly type = '[Catalogue] GetMicrosoftCatalogue';
  constructor(public filterSettings: ICatalogueFilter) {}
}

export class GetPopularMicrosoftLicenses {
  static readonly type = '[Catalogue] GetPopularMicrosoftLicenses';
  constructor(public filterSettings: ICatalogueFilter) {}
}

export class GetPopularMicrosoftAddons {
  static readonly type = '[Catalogue] GetPopularMicrosoftAddons';
  constructor(public filterSettings: ICatalogueFilter) {}
}
